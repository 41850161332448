import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

const CleanupEvents = () => {
  return (
    <Layout>
      <Metadata title="Cleanup Events" description="Register for our next beach cleanup event." />
      <div>
        <h1>Cleanup Events</h1>
        <p>Lorem ipsum.</p>
      </div>
    </Layout>
  )
}

export default CleanupEvents